<!-- 支付页面 -->


<template>
    <div class="stop">
        <div class="stop-remind">
            <div>抱歉，该网站暂不支持微信</div>
            <div>端，请您用手机浏览器或电脑打开，谢谢！</div>
        </div>
        
        <!-- <div class="stop-img">
            <img width="60" height="60" src="../assets/stop.png" alt="">
        </div>
        <div class="stop-tips">
            <span>已停止访问该网页</span>
        </div>
        <div class="stop-text">
            <div>据用户投诉及腾讯安全网址安全中心检</div>
            <div>测，该网页包含不安全内容。为维护绿色</div>
            <div>上网环境，已停止访</div>
        </div> -->
    </div>
</template>

<script>
import dialogVue from './dialog.vue'

export default {
    components: {
        dialogVue
    },
    data() {
        return {
            dialogVisible: true,
            dialogVueVisible: false,
            textarea: '123456',
            count: 0,
            dataForm: {
                money: '',
                code: '',
            },
            timer: null,
        }
    },
}
</script>

<style lang="scss" scoped>
.stop {
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    &-remind {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        line-height: 35px;
        top: 30%;
        left: 0;
        color: #000;
    }

    &-img {
        width: 60px;
        height: 60px;
        margin: 80px auto 20px;
    }

    &-tips {
        width: 100%;
        line-height: 80px;
        text-align: center;
        font-size: 24px;
        font-weight: bolder;
        font-family: 'PingFang SC';
        color: #000;
    }

    &-text {
        width: 100%;
        padding: 20px 20px 0;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        font-family: 'PingFang SC';
        color: #000;
    }
}
</style>
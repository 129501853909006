<template>
    <div class="popUp">
        <el-dialog title="购买页面" :visible.sync="dialogVisible" :width="isMobile ? '92%' : '75%'"
            :before-close="handleClose" :close-on-click-modal="false" append-to-body>
            <div style="width: 100%;">
                <div class="popUp-goodsclassification">
                    <div class="popUp-goodsclassification-name"><span>商品分类：</span></div>
                    <div class="popUp-goodsclassification-content">{{ dataList.categoryName }}</div>
                </div>
                <div class="popUp-goodsclassification">
                    <div class="popUp-goodsclassification-name"><span>商品名称：</span></div>
                    <div class="popUp-goodsclassification-content">{{ dataList.goodsName }}</div>
                </div>
                <div class="popUp-goodsclassification">
                    <div class="popUp-goodsclassification-content" style="text-indent: 15px;">
                        <span>单价：</span><span style="color: #03a9f4;">{{ dataList.price }}</span>
                    </div>
                    <div class="popUp-goodsclassification-content"><span>库存：</span><span style="color: #03a9f4;">{{
            dataList.stock }}</span></div>
                    <div class="popUp-goodsclassification-content"><span>优惠券：</span><span style="color: #03a9f4;">{{
            dataList.isCoupon == 0 ? '不支持' : '支持' }}</span></div>
                </div>
                <div class="popUp-tips" v-html="dataList.description"></div>
                <div class="popUp-num">
                    <div class="popUp-num-text">
                        <span
                            style="display: inline-block;text-align: left;margin-right: 10px;line-height: 40px;">购买数目：</span>
                        <el-input-number v-model="num" @change="handleChange" :min="dataList.stock > 0 ? 1 : 0"
                            label="描述文字"></el-input-number>
                    </div>
                    <div class="popUp-num-text1">
                        <span>{{ '￥' + ' ' + sum.toFixed(2) }}</span>
                    </div>
                </div>
                <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="370" class="demo-ruleForm"
                    style="margin-top: 15px;">
                    <el-form-item label="联系方式：" prop="contactWay">
                        <el-input v-model="dataForm.contactWay" placeholder="可以输入QQ、邮箱、手机号等等，用于查询订单"></el-input>
                    </el-form-item>
                    <el-form-item label="查询密码：" prop="searchPassword">
                        <el-input v-model="dataForm.searchPassword" placeholder="请输入订单查询密码"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠券：" prop="coupon" style="position: relative;" v-if="dataList.isCoupon == 1">
                        <el-input v-model="dataForm.coupon" placeholder="请输入优惠券（没有可不填）" @blur="blur"></el-input>
                        <div id="coupon">优惠券信息无效</div>
                    </el-form-item>
                </el-form>
                <div class="popUp-payment">
                    <div class="popUp-payment-name"><span>支付方式：</span></div>
                    <div class="popUp-payment-picture">
                        <div class="popUp-payment-picture-Alipay" v-for="item, index in list" :key="index"
                            @click="click(index)" :class="activeIndex == index ? 'popUpactive' : ''">
                            <div class="popUp-payment-picture-Alipay-img">
                                <img :src="item.imgUrl" alt="" v-if="index == 0">
                                <div class="popUp-payment-picture-Alipay-img-content"
                                    v-if="index == 1 && balance == true">
                                    <div class="popUp-payment-picture-Alipay-img-content-left">
                                        <img :src="item.imgUrl" alt=""
                                            :style="{ 'width': isMobile ? '40px' : '55px', 'height': isMobile ? '40px' : '55px' }">
                                    </div>
                                    <div class="popUp-payment-picture-Alipay-img-content-right">余额支付</div>
                                </div>
                            </div>
                            <div class="popUp-payment-picture-Alipay-text" v-if="index == 0"><span>{{ item.title
                                    }}</span></div>
                            <div class="popUp-payment-picture-Alipay-text" v-if="index == 1 && balance == true">
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popUp-goodsclassification" style="margin: 25px 0;">
                    <el-button type="primary" @click="pay">立即支付</el-button>
                </div>
            </div>
            <el-dialog :width="isMobile ? '92%' : flag == true ? '420px' : '75%'" :title="sign == 0 ? '确认购买' : '提示'"
                :visible.sync="innerVisible" append-to-body>
                <div v-if="sign == 0">
                    <div class="popUp-inner" style="position: relative;">
                        <div class="popUp-inner-left"
                            style="width: 100px;height: 32px;line-height: 32px;position: absolute;top: 0; left: 0;">
                            购买商品：
                        </div>
                        <div class="popUp-inner-right" style="margin-left: 80px;">{{ dataList.goodsName }}</div>
                    </div>
                    <div class="popUp-inner">
                        <div class="popUp-inner-left">商品单价：</div>
                        <div class="popUp-inner-right" style="color: #ff7a22;">￥{{ dataList.price }}</div>
                    </div>
                    <div class="popUp-inner">
                        <div class="popUp-inner-left">购买件数：</div>
                        <div class="popUp-inner-right">{{ num }}</div>
                    </div>
                    <div class="popUp-inner">
                        <div class="popUp-inner-left">支付金额：</div>
                        <div class="popUp-inner-right" style="color: #ff7a22;">￥{{ Number(sum).toFixed(2) }}</div>
                    </div>
                    <div class="popUp-inner">
                        <div class="popUp-inner-left">支付方式：</div>
                        <div class="popUp-inner-right">{{ activeIndex == 0 ? list[0].title : list[1].title }}</div>
                    </div>
                </div>
                <div v-if="sign == 1">
                    <span>请在弹出的窗口中完成支付</span>
                </div>
                <div class="popUp-innerpay">
                    <div class="popUp-innerpay-left" @click="innerVisible = false">取消</div>
                    <div class="popUp-innerpay-right orderstr" @click="submit" v-if="flag == false">确定支付</div>
                    <div class="popUp-innerpay-right" @click="searchOrder" v-if="flag == true">查询订单</div>
                </div>
            </el-dialog>
        </el-dialog>
        <commonPopup :tips="tips" :content="content" v-if="commonPopupVisable"></commonPopup>
        <dialogMobile v-if="dialogMobileVisable" ref="dialogMobile"></dialogMobile>
        <paymentMobile v-if="paymentMobileVisable" ref="paymentMobile"></paymentMobile>
    </div>
</template>

<script>
import agent from '../../utils/agent'
import commonPopup from '../../components/common-popup.vue'
import paymentMobile from '../../components/paymentMobile.vue'
import dialogMobile from '../../components/dialogMobile.vue'


export default {
    components: {
        commonPopup,
        paymentMobile,
        dialogMobile
    },
    data() {
        return {
            commonPopupVisable: false,
            dialogMobileVisable: false,
            paymentMobileVisable: false,
            tips: '',
            content: '',
            sign: 0,
            dialogVisible: false,
            innerVisible: false,
            balance: false,
            status: true,
            title: '',
            isMobile: false,
            flag: false,
            dataList: {
                categoryName: '',
                goodsName: '',
                price: '',
                stock: '',
                isCoupon: '',
                description: '',
            },
            dataForm: {
                contactWay: '',
                searchPassword: '',
                coupon: '',
                price: 0,
                payWay: 1,
                goodsId: '',
                buyNum: 0,
                actualPrice: 0
            },
            num: 1,
            input1: '',
            input2: '',
            input3: '',
            list: [
                { title: '前台支付方式-支付宝', imgUrl: require('../../assets/Alipay.png') },
                { title: '前台支付方式-余额', imgUrl: require('../../assets/余额.png') },
            ],
            activeIndex: 0,
            rules: {
                contactWay: [
                    { required: true, message: '此项是必填项', trigger: 'blur' },
                    { min: 6, message: '长度不低于6位', trigger: 'blur' }
                ],
                searchPassword: [
                    { required: true, message: '此项是必填项', trigger: 'blur' },
                    { min: 6, message: '长度不低于6位', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        this.isMobile = agent()
        if (this.isMobile) {
            console.log('移动端--------');
            import('../../assets/CSS/mobile/popUp.scss')
        } else {
            console.log('PC端--------');
            import('../../assets/CSS/desktop/popUp.scss')
        }
        this.sign = 0
    },
    methods: {
        init(id) {
            let someData = this.$store.state.someData
            if (someData) {
                this.balance = true
            } else {
                this.balance = false
            }
            this.sign = 0
            this.dialogVisible = true
            this.dataForm.contactWay = '';
            this.dataForm.searchPassword = '';
            this.dataForm.coupon = '';
            if (this.$refs.dataForm !== undefined) {
                this.$refs.dataForm.resetFields();
            }
            this.$http.get(`/sell/goodsDetails/${id}`).then((res) => {
                if (res.code == 0) {
                    this.dataList = res.data
                    this.dataForm.price = this.dataList.price
                    this.dataForm.goodsId = id
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        handleClose() {
            this.dialogVisible = false
            let isMobile = agent()
            // if(isMobile){
            this.$parent.$parent.shuaxin()
            // }
        },
        handleChange(value) {
            console.log(value);
        },
        closeCommonPopup() {
            this.commonPopupVisable = false
        },
        click(index) {
            this.activeIndex = index
            this.dataForm.payWay = index + 1
        },
        pay() {
            this.flag = false;
            this.$refs.dataForm.validate((valid) => {
                if (valid && this.status == true) {
                    this.innerVisible = true
                    this.flag = false;
                }
            })
        },
        back() {

        },
        close() {
            this.dialogMobileVisable = false
            this.paymentMobileVisable = false
        },
        submit() {
            let num = 0
            this.dataForm.actualPrice = this.dataForm.actualPrice ? Number(this.dataForm.actualPrice).toFixed(2) : num.toFixed(2)
            this.dataForm.websiteOwner = sessionStorage.getItem('websit_owner') ? sessionStorage.getItem('websit_owner') : ''
            if (this.dataForm.payWay == 1) {
                this.$http.post(`/sell/payOrder`, this.dataForm).then((res) => {
                    if (res.code == 0) {
                        this.flag = true
                        this.sign = 1
                        let isMobile = agent()
                        if (isMobile) {
                            this.paymentMobileVisable = true
                            setTimeout(() => {
                                this.$refs.paymentMobile.init(res.data)
                            }, 10);
                        } else {
                            var onlocal = window.open('', ' _blank');
                            const { href } = this.$router.resolve({ path: '/payment', query: res.data })
                            onlocal.location.href = href
                        }
                    } else {
                        this.commonPopupVisable = true
                        this.tips = '提示'
                        this.content = res.msg
                        this.innerVisible = false
                        this.dialogVisible = false
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                this.$http.post(`/sell/balancePay`, this.dataForm).then((res) => {
                    if (res.code == 0) {
                        this.flag = true
                        this.sign = 1
                        if (isMobile) {
                            this.dialogMobileVisable = true
                            setTimeout(() => {
                                this.$refs.dialogMobile.init(dataForm.orderNo)
                            }, 10);
                        } else {
                            var onlocal = window.open('', ' _blank');
                            const { href } = this.$router.resolve({ path: '/dialogS', query: { orderNo: res.data } })
                            onlocal.location.href = href
                        }
                    } else {
                        this.commonPopupVisable = true
                        this.tips = '提示'
                        this.content = res.msg
                        this.innerVisible = false
                        this.dialogVisible = false
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        },
        searchOrder() {
            this.sign = 0;
            this.innerVisible = false
            this.dialogVisible = false
            this.$parent.$parent.clickTabs(1)
        },
        blur() {
            if (this.dataForm.coupon) {
                this.$http.get(`/sell/getCoupons`,
                    {
                        params: {
                            goodsId: this.dataForm.goodsId,
                            coupons: this.dataForm.coupon,
                        }
                    }
                ).then((res) => {
                    if (res.code == 0) {
                        this.status = true
                    } else {
                        let coupon = document.getElementById('coupon')
                        coupon.style.display = 'block'
                        this.status = false
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
            if (this.dataForm.coupon == '' || this.dataForm.coupon == null) {
                this.status = true
                let coupon = document.getElementById('coupon')
                coupon.style.display = 'none'
            }
        },
    },
    computed: {
        sum() {
            let num = this.num * this.dataList.price
            this.dataForm.buyNum = this.num
            this.dataForm.actualPrice = num
            return num
        },
    },
    beforeDestroy() {
        this.dialogVisible = false
    }
}
</script>

<style>
/* 针对移动端 */
@media screen and (max-width: 768px) {
    .el-dialog__body {
        padding: 0 15px 1px !important;
    }

    .el-form-item {
        margin-bottom: 8px !important;
    }

    .el-form,
    .demo-ruleForm {
        margin-top: 8px !important;
    }
}
</style>
<template>
    <div class="QueryRecords">
        <div class="QueryRecords-content">
            <div class="QueryRecords-content-title">订单方式查询：</div>
            <div class="QueryRecords-content-tab">
                <div v-for="item, index in list" :key="index" @click="clickTab(index)" style="cursor: pointer;"
                    :class="activeIndex == index ? 'active' : ''" class="QueryRecords-content-tab-name"><span>{{
                    item.title
                }}</span></div>
            </div>
            <div class="QueryRecords-content-table">
                <div class="QueryRecords-content-table-Cache" v-if="flag == 0">
                    <div class="QueryRecords-content-table-Cache-tip1">订单提交时浏览器会自动保存您的订单信息，不同浏览器可能显示结果不同。</div>
                    <div class="QueryRecords-content-table-Cache-tip1-button" style="text-align: center;"><button
                            @click="clickSearch(1)"><span>查询</span></button></div>
                    <div class="QueryRecords-content-table-Cache-tip2">订单查询仅能查询最近购买的数据。</div>
                </div>

                <div class="QueryRecords-content-table-Cache" v-if="flag == 1">
                    <div class="QueryRecords-content-table-Cache-tip1">订单号</div>
                    <div class="QueryRecords-content-table-Cache-button">
                        <el-input v-model="orderNo" placeholder="请输入订单号"></el-input>
                        <el-button @click="clickSearch(2)" class="search">查询</el-button>
                    </div>
                    <div class="QueryRecords-content-table-Cache-tip2">订单查询仅能查询最近购买的数据。</div>
                </div>
                <div class="QueryRecords-content-table-Cache" v-if="flag == 2">
                    <div class="QueryRecords-content-table-Cache-tip1">联系方式</div>
                    <div class="QueryRecords-content-table-Cache-button">
                        <el-input v-model="contactWay" placeholder="请输入联系方式"></el-input>
                        <el-button @click="searched" class="search">查询</el-button>
                    </div>
                    <div class="QueryRecords-content-table-Cache-tip2">订单查询仅能查询最近购买的数据。</div>
                </div>
                <el-dialog title="查询密码" :visible.sync="dialogVisible" width="92%" :before-close="handleClose">
                    <el-input v-model="searchPassword" placeholder="请输入购买时填写的订单查询密码"></el-input>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                        <el-button size="mini" type="primary" @click="dialogVisible = false, clickSearch(3)">确
                            定</el-button>
                    </span>
                </el-dialog>
            </div>

            <div class="QueryRecords-content-table" style="margin-top: 15px;" v-if="flage == true">
                <div v-if="dataForm.length > 0">
                    <div class="QueryRecords-content-table-lineament" v-for="item, index in dataForm" :key="index">
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>商品名称</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.goodsName
                                    }}</span></div>
                        </div>
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>下单时间</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.createDate
                                    }}</span></div>
                        </div>
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>订单号</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.orderNo }}</span>
                            </div>
                        </div>
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>订单数目</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.buyNum }}</span>
                            </div>
                        </div>
                        <!-- <div class="QueryRecords-content-table-lineament-tr" v-if="item.cami">
                        <div class="QueryRecords-content-table-lineament-tr-divOne"><span>卡密信息</span></div>
                        <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.cami }}</span></div>
                    </div> -->
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>订单信息</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo">
                                <span v-if="item.status == 1">{{ '未支付' }} <span style="color: #03a9f4;cursor: pointer;"
                                        @click="camiDialog(item.id)">{{ '支付' }}</span></span>
                                <span style="color: #03a9f4;cursor: pointer;" v-if="item.status == 2"
                                    @click="searchCami(item.orderNo)">{{ '查看卡密' }}</span>
                            </div>
                        </div>
                        <div class="QueryRecords-content-table-lineament-tr">
                            <div class="QueryRecords-content-table-lineament-tr-divOne"><span>联系信息</span></div>
                            <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.contactWay
                                    }}</span></div>
                        </div>
                        <!-- <div class="QueryRecords-content-table-lineament-tr">
                        <div class="QueryRecords-content-table-lineament-tr-divOne"><span>附加信息</span></div>
                        <div class="QueryRecords-content-table-lineament-tr-divTwo"><span>{{ item.addMessage }}</span>
                        </div>
                    </div> -->
                    </div>
                </div>
                <div v-else style="text-align: center;">
                    <span>暂无数据</span>
                </div>
                <div class="QueryRecords-content-table-more" v-if="dataForm.length > 0 && total > pageSize">
                    <span style="color: #1890ff;cursor: pointer;" @click="moreData()">查看更多</span>
                </div>
            </div>
        </div>
        <commonPopup v-if="commonPopupVisable" tips="提示" :content="content" ref="commonPopup"></commonPopup>
    </div>
</template>

<script>
import agent from '../../utils/agent'
import commonPopup from '../../components/common-popup.vue'

export default {
    components: {
        commonPopup
    },
    data() {
        return {
            total: 0,
            content: '',
            dialogVisible: false,
            isMobile: false,
            list: [
                { title: '浏览器缓存' },
                { title: '订单号' },
                { title: '联系方式' },
            ],
            commonPopupVisable: false,
            activeIndex: 0,
            flag: 0,
            flage: false,
            flageOne: false,
            input: '',
            type: 0,
            orderNo: '',
            contactWay: '',
            searchPassword: '',
            goodList: {
                goodName: 'Microsoft Outlook邮箱---包登入----登入不了联系客服补',
                orderTime: '2023-12-31 14:35:37',
                orderId: '20231231143537X6cG5',
                orderNum: '1 ( ￥0.70)',
                orderInformation: '',
                phone: '1105431607@qq.com',
                addMessage: '无'
            },
            dataForm: [],
            pageNum: 1,
            pageSize: 10,
        }
    },
    methods: {
        init(index) {
            this.dataForm = []
            this.flage = false
            this.type = index
            if (this.type == 1) {
                this.orderNo = '';
                this.contactWay = ''
                this.searchPassword = ''
                this.$http.get(`/sell/queryOrder`, {
                    params: {
                        pageNum: this.pageNum ? this.pageNum : null,
                        pageSize: this.pageSize ? this.pageSize : null,
                        type: this.type,
                        orderNo: this.orderNo,
                        contactWay: this.contactWay,
                        searchPassword: this.searchPassword,
                    }
                }).then((res) => {
                    if (res.code == 0) {
                        this.flage = true
                        this.dataForm = res.data.list
                        this.total = res.data.total
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
            if (this.type == 2) {
                if (this.orderNo == '') {
                    this.$message.warning('订单号不能为空')
                } else {
                    this.$http.get(`/sell/queryOrder`, {
                        params: {
                            pageNum: this.pageNum ? this.pageNum : null,
                            pageSize: this.pageSize ? this.pageSize : null,
                            type: this.type,
                            orderNo: this.orderNo,
                            contactWay: this.contactWay,
                            searchPassword: this.searchPassword,
                        }
                    }).then((res) => {
                        if (res.code == 0) {
                            this.flage = true
                            this.dataForm = res.data.list
                            this.total = res.data.total
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
            if (this.type == 3) {
                if (this.contactWay == '') {
                    this.$message.warning('联系方式不能为空')
                } else {
                    this.$http.get(`/sell/queryOrder`, {
                        params: {
                            pageNum: this.pageNum ? this.pageNum : null,
                            pageSize: this.pageSize ? this.pageSize : null,
                            type: this.type,
                            orderNo: this.orderNo,
                            contactWay: this.contactWay,
                            searchPassword: this.searchPassword,
                        }
                    }).then((res) => {
                        if (res.code == 0) {
                            this.flage = true
                            this.dataForm = res.data.list
                            this.total = res.data.total
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
            // sessionStorage.removeItem('search')
        },
        deleteRow(index, rows) {
            rows.splice(index, 1);
        },
        clickTab(index) {
            this.pageSize = 10;
            this.total = 0;
            this.activeIndex = index
            this.flag = index
            this.flage = false
        },
        clickSearch(index) {
            this.init(index)
        },
        closeCommonPopup() {
            this.commonPopupVisable = false
        },
        camiDialog(id) {
            this.$http.get(`/sell/queryOrderPay/${id}`).then((res) => {
                if (res.code == 0) {
                    var onlocal = window.open('', ' _blank');
                    const { href } = this.$router.resolve({ path: '/payment', query: res.data })
                    onlocal.location.href = href
                } else {
                    let isMobile = agent()
                    if (isMobile) {
                        this.commonPopupVisable = true
                        this.content = res.msg
                    } else {
                        this.$alert(res.msg, '提示', {
                            dangerouslyUseHTMLString: true
                        });
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        searched() {
            this.dialogVisible = true
        },
        handleClose() {
            this.dialogVisible = false
        },
        searchCami(orderNo) {
            const { href } = this.$router.resolve({ path: '/dialogS', query: { orderNo: orderNo } })
            window.open(href, '_blank')
            // this.$router.push({ path: '/dialogS', query: { orderNo: orderNo} })
        },
        moreData() {
            this.pageSize += 10;
            this.init(this.type)
        }
    },
    mounted() {
        this.isMobile = agent()
        if (this.isMobile) {
            console.log('移动端--------');
            import('../../assets/CSS/mobile/QueryRecords.scss')
        } else {
            console.log('PC端--------');
            import('../../assets/CSS/desktop/QueryRecords.scss')
        }
        // let search = sessionStorage.getItem('search') ? sessionStorage.getItem('search') : ''
        // if (search) {
        //     this.init(1)
        // }
    }
}
</script>

<style lang="scss" scoped>
.customClass {
    width: 200px !important;
}
</style>
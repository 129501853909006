import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from '@/utils/request';
import 'lib-flexible';
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$http = http

new Vue({
  router,
  store,
  render: h => h(App),
  // mounted() {
  //   const userAgent = navigator.userAgent;
  //   const isMobile = /Mobile|iPhone|iPad|iPod|Android/i.test(userAgent);
  //   if (isMobile) {
  //     // 重定向到错误页面或显示提示信息  
  //     router.push('/error');
  //     this.$alert('抱歉，该网站暂不支持手机端，请您用电脑打开，谢谢！')
  //   }
  // }
}).$mount('#app')

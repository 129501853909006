<!-- 支付页面 -->


<template>
    <div class="payment">
        <div class="payment-top">
            <div class="payment-top-header">
                <div class="payment-top-header-img">
                    <img src="~@/assets/支付宝支付.png" alt="" style="width: 40px;height: 40px;">
                </div>
                <div class="payment-top-header-text">{{ '支付宝扫码' }}</div>
            </div>
        </div>
        <div class="payment-body">
            <div class="payment-body-pay">
                <div class="payment-body-pay-figure"><img src="~@/assets/花纹.png" width="100%" alt=""></div>
                <div class="payment-body-pay-money">
                    {{ '￥' + dataForm.actualPrice }}
                </div>
                <div class="payment-body-pay-code">
                    <div class="payment-body-pay-code-img">
                        <img :src="dataForm.picture" alt="" style="width: 230px; height: 230px;">
                    </div>
                    <div class="payment-body-pay-code-information" ref="codebutton">
                        <div class="payment-body-pay-code-information-framework">
                            <div class="payment-body-pay-code-information-framework-goods"
                                style="position: relative;">
                                <div class="payment-body-pay-code-information-framework-goods-title"
                                    style="width: 100px;height: 20px;line-height: 20px;position: absolute;top: 0; left: 0;">
                                    商品</div>
                                <div class="payment-body-pay-code-information-framework-goods-content"
                                    style="margin-left: 61.11px;">{{ dataForm.goodsName }}
                                </div>
                            </div>
                            <div class="payment-body-pay-code-information-framework-goods">
                                <div class="payment-body-pay-code-information-framework-goods-title">订单号</div>
                                <div class="payment-body-pay-code-information-framework-goods-content">{{
                    dataForm.orderNo
                }}
                                </div>
                            </div>
                            <div class="payment-body-pay-code-information-framework-goods">
                                <div class="payment-body-pay-code-information-framework-goods-title">时间</div>
                                <div class="payment-body-pay-code-information-framework-goods-content">{{
                        dataForm.createDate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-body-pay-code-button" @click="expand">
                        <img src="~@/assets/下拉按钮.png" alt="" style="width: 20px;" v-if="count % 2 == 1">
                        <img src="~@/assets/按钮.png" alt="" style="width: 20px;" v-if="count % 2 == 0">
                    </div>
                </div>
                <div class="payment-body-pay-position">
                    <div class="payment-body-pay-position-line"></div>
                </div>
                <div class="payment-body-pay-scan">
                    <div class="payment-body-pay-scan-img">
                        <img src="~@/assets/扫一扫.png" alt="" style="width: 55px;">
                    </div>
                    <div class="payment-body-pay-scan-text">
                        请使用支付宝扫一扫
                    </div>
                </div>
                <div class="payment-body-pay-statement">
                    有疑问请联系客服
                </div>
            </div>
        </div>
        <dialogVue ref="dialog" v-if="dialogVueVisible"> </dialogVue>
        <dialogMobile v-if="dialogMobileVisable" ref="dialogMobile"></dialogMobile>
    </div>
</template>

<script>
import agent from '../utils/agent'
import dialogVue from './dialog.vue'
import dialogMobile from './dialogMobile.vue'

export default {
    components: {
        dialogVue,
        dialogMobile
    },
    data() {
        return {
            dialogVisible: true,
            dialogVueVisible: false,
            dialogMobileVisable: false,
            textarea: '123456',
            count: 0,
            dataForm: {
                money: '',
                code: '',
            },
            timer: null,
        }
    },
    methods: {
        init(dataForm) {
            let isMobile = agent()
            if (isMobile) {
                console.log('移动端--------');
                import('../assets/CSS/mobile/payment.scss')
            } else {
                console.log('PC端--------');
                import('../assets/CSS/desktop/payment.scss')
            }
            this.dataForm = dataForm
            this.timer = setInterval(() => {
                this.$http.get(`/sell/getOrder/${dataForm.orderNo}`).then((res) => {
                    if (res.code == 0) {
                        if (res.data == 'TRADE_SUCCESS') {
                            this.dialogMobileVisable = true
                            setTimeout(() => {
                                this.$refs.dialogMobile.init(dataForm.orderNo)
                            }, 200);
                        }
                        if (res.data == 'TRADE_CLOSED') {
                            clearInterval(this.timer)
                            this.$message.error('订单已超时，已关闭交易，如需购买此商品，可重新下单！');
                            this.$router.push({ name: 'home', query: { sign: '0' } })
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }, 3000)
        },
        close(){
            this.dialogMobileVisable = false
            this.$parent.close()
        },
        expand() {
            this.count++
            let codebutton = this.$refs.codebutton
            if (this.count % 2 == 1) {
                codebutton.style.display = 'none'
            } else {
                codebutton.style.display = 'block'
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
:deep(.el-textarea.is-disabled .el-textarea__inner) {
    color: #000 !important;
    cursor: default;
    background-color: #fff;
}

.payment{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
}


</style>
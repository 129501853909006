<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: '',
    }
  },
  methods: {

  },
  mounted() {
    this.$http.get(`/sell/getMembers`).then(({ data: res }) => {
      if (res) {
        if (res.code == 0) {
          this.$store.commit('setSomeData', true)
        }
        if (res.code != 0) {
          this.$store.commit('setSomeData', false)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
    document.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('gesturestart', function (event) {
      event.preventDefault()
    })
  }
}
</script>


<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    someData: false
  },
  getters: {
  },
  mutations: {
    setSomeData(state, someData) {  
      state.someData = someData;  
    }  
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})

<template>
  <div>
    <div v-if="isWeChat == false">
      <div v-if="loading == true"></div>
      <div class="homeView" v-if="loading == false">
        <!-- 头部信息 -->
        <div class="homeView-header">
          <div class="homeView-header-left">
            <div class="homeView-header-content">
              <div class="homeView-header-content-title"><span @click="homeClick">{{ topTitle }}</span></div>
              <div class="homeView-header-content-button">
                <div v-for="(item, index) in list" :key="index" @click="clickTab(index)" style="width: 50%;">
                  <div class="homeView-header-content-button-scrsor" :class="activeIndex == index ? 'active' : ''">
                    <div v-if="isMobile == true && activeIndex == index" v-show="isMobile"
                      class="homeView-header-content-button-scrsor-iconActive">
                      <img width="24" height="24" :src="item.iconActive" alt="" />
                    </div>
                    <div v-else v-show="isMobile" class="homeView-header-content-button-scrsor-icon">
                      <img width="24" height="24" :src="item.icon" alt="" />
                    </div>
                    <div @click="quit(item.name)">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="homeView-header-right"></div>
        </div>
        <!-- 主体 -->
        <div class="homeView-body">
          <div v-if="flag == 0">
            <purchase-goods ref="PurchaseGoodsVues"></purchase-goods>
            <ManagementPlatform ref="ManagementPlatform"></ManagementPlatform>
          </div>
          <div v-if="flag == 1">
            <QueryRecordsVue ref="QueryRecordsVue"></QueryRecordsVue>
          </div>
          <div v-if="flag == 2">
            <personal ref="personal" v-if="!login"></personal>
            <loginVue v-if="login"></loginVue>
          </div>
        </div>
        <div class="homeView-footer"><span>{{ footerTitle }}</span></div>
        <el-dialog title="店铺公告" :visible.sync="dialogVisible" :before-close="handleClose" append-to-body
          :width="isMobile ? '92%' : '600'">
          <div id="box-show" class="homeView-dialog" v-loading="loading" v-html="title"></div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <stopVue v-if="isWeChat == true"></stopVue>
  </div>
</template>

<script>
import agent from '../utils/agent'
import { Loading } from 'element-ui';
import PurchaseGoodsVue from '@/components/PurchaseGoods.vue'
import ManagementPlatform from '@/components/ManagementPlatform.vue'
import QueryRecordsVue from './QueryRecords/QueryRecords.vue'
import stopVue from '@/components/stop.vue'
import personal from '@/components/personal.vue'
import loginVue from './login.vue'

export default {
  name: 'home',
  components: {
    'purchase-goods': PurchaseGoodsVue,
    ManagementPlatform,
    QueryRecordsVue,
    stopVue,
    personal,
    loginVue
  },
  data() {
    return {
      login: false,
      isMobile: false,
      loading: false,
      topTitle: '',
      footerTitle: '',
      activeIndex: 0,
      dialogVisible: true,
      flag: 0,
      list: [],
      title: '',
      obj: {},
      isWeChat: false,
    }
  },
  // computed: {
  //   // 使用计算属性从Vuex中获取数据  
  //   someDataFromStore() {
  //     console.log(this.$store.state.someData , 444444444);
  //     if(this.$store.state.someData == false){
  //       this.loginout()
  //     }
  //   }
  // },
  methods: {
    shuaxin() {
      this.flag = 0
      this.dialogVisible = true
      this.init()
      // setTimeout(() => {
      //   this.$refs.PurchaseGoodsVues.init()
      //   this.$refs.ManagementPlatform.init()
      // }, 400)
    },
    checkImg() {
      this.obj = document.getElementById('box-show').getElementsByTagName('img')
      if (this.obj) {
        for (let i = 0; i < this.obj.length; i++) {
          this.getImgInfo(this.obj[i].src, i)
        }
      }
    },
    getImgInfo(url, i) {
      let that = this;
      let image = new Image();
      let w, h;
      image.src = url;
      image.onload = function () {
        w = image.width
        h = image.height
        let windowWidth = document.documentElement.clientWidth
        if (windowWidth <= w) {
          that.obj[i].style.width = w / 1920 * windowWidth + 'px'
        }
        image.onload = null
      }
    },
    fetchData() {
      const id = this.$route.params.id;
      this.getDataFromApi(id);
    },
    getDataFromApi(id) {
      this.loading = true
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$http.get(`/sell/website/${id}`).then((res) => {
        if (res.code == 0) {
          this.topTitle = res.data.home_name;
          this.footerTitle = res.data.bottom_copyright
          sessionStorage.setItem('websit_owner', res.data.websit_owner)
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
            this.loading = false
          });
        } else {
          this.$router.push('/404')
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
            this.loading = false
          });
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    homeClick() {
      this.clickTab(0)
      this.$router.go(0)
    },
    init() {
      this.loading = true
      let loadingInstance = Loading.service();
      this.$http.get(`/sell/storeNotice`).then((res) => {
        if (res.code == 0) {
          this.title = res.data
          this.loading = false
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
          setTimeout(() => {
            let isMobile = agent()
            if (isMobile) {
              this.$nextTick(() => {
                this.checkImg()
              })
            }
          }, 100)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickTab(index) {
      this.activeIndex = index
      this.flag = index
      if (index == 0) {
        this.shuaxin()
      }
      this.loginout()
      if (index == 2) {
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '退出登录', iconActive: require('../assets/loginoutActive.png'), icon: require('../assets/loginout.png') }
        ]
        this.loginout()
      } else {
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '个人中心', iconActive: require('../assets/personalActive.png'), icon: require('../assets/personal.png') }
        ]
        this.loginout1()
      }
    },
    clickTabs(index) {
      this.activeIndex = index
      this.flag = index
      setTimeout(() => {
        this.$refs.QueryRecordsVue.init(1)
      }, 500);
    },
    handleClose() {
      this.dialogVisible = false
    },
    quit(Tabname) {
      if (Tabname == '退出登录') {
        this.$http.get(`/sell/loginOut`).then((res) => {
          if (res.code == 0) {
            this.login = true
          }
        }).catch(() => { })
      } else {
        this.login = false
      }

    },
    loginout() {
      let someData = this.$store.state.someData
      if (someData == false) {
        this.login = true
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '登录/注册', iconActive: require('../assets/loginoutActive.png'), icon: require('../assets/loginout.png') }
        ]
      }
      if (someData == true) {
        this.login = false
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '退出登录', iconActive: require('../assets/personalActive.png'), icon: require('../assets/personal.png') }
        ]
      }
    },
    loginout1() {
      let someData = this.$store.state.someData
      if (someData == false) {
        this.login = true
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '登录/注册', iconActive: require('../assets/loginoutActive.png'), icon: require('../assets/loginout.png') }
        ]
      }
      if (someData == true) {
        this.login = false
        this.list = [
          { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
          { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
          { name: '个人中心', iconActive: require('../assets/personalActive.png'), icon: require('../assets/personal.png') }
        ]
      }
    },
    loginPersonal() {
      this.login = false
      this.list = [
        { name: '购买商品', iconActive: require('../assets/cartActive.png'), icon: require('../assets/cart.png') },
        { name: '查询记录', iconActive: require('../assets/searchActive.png'), icon: require('../assets/search.png') },
        { name: '退出登录', iconActive: require('../assets/personalActive.png'), icon: require('../assets/personal.png') }
      ]
      setTimeout(() => {
        this.$refs.personal.init()
      }, 200);
    },
  },
  watch: {
    '$route.path': 'fetchData', // 当 $route 对象变化时，调用 fetchData 方法  
    '$store.state.someData': 'loginout',
  },
  mounted() {
    this.init()
    this.isWeChat = /MicroMessenger/.test(navigator.userAgent);
    this.isMobile = false;
    this.isMobile = agent()
    if (this.isMobile) {
      console.log('移动端--------');
      import('../assets/CSS/mobile/homeView.scss')
    } else {
      console.log('PC端--------');
      this.isMobile = false;
      import('../assets/CSS/desktop/homeView.scss')
    }

    if (this.$route.query.hasOwnProperty('sign') == '1') {
      this.clickTab(1)
    }
    if (this.$route.query.hasOwnProperty('sign') == '0') {
      this.clickTab(0)
    }
    this.loginout()
  },
  created() {
    this.fetchData();
  }
}
</script>

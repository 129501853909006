<template>
    <div class="commonComponent">
        <div class="commonPopup">
            <div class="commonPopup-tips">
                <div class="commonPopup-tips-left">{{ tips }}</div>
                <div class="commonPopup-tips-right" @click="close"><i class="el-icon-close"></i></div>
            </div>
            <div class="commonPopup-content">{{ content }}</div>
            <div class="commonPopup-button">
                <el-button type="primary" size="mini" @click="close">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        tips: {
            type: String,
            required: true,
            default: ''
        },
        content: {
            type: String,
            required: true,
            default: ''
        },
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        close() {
            this.$parent.closeCommonPopup()
        }
    }
}
</script>

<style lang="scss" scoped>
.commonComponent {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

.commonPopup {
    position: fixed;
    top: 40%;
    left: 5%;
    width: 90%;
    z-index: 999999;
    padding: 15px;
    box-sizing: border-box;
    background-color: #ffffff;
    // box-shadow: 0 0 2px 2px #efefef;

    &-tips {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        padding: 0 10px;
        text-align: left;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        box-sizing: border-box;


        &-right {
            margin-top: -8px;
        }
    }

    &-content {
        width: 95%;
        line-height: 28px;
        font-size: 14px;
        text-align: left;
        padding: 5px 15px;
    }

    &-button {
        width: 100%;
        text-align: right;
    }
}

@media screen and (min-width: 750px) {

    /*当屏幕尺寸大于600px时，应用下面的CSS样式*/
    .commonPopup {
        width: 420px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
<template>
    <div class="lose"></div>
</template>


<script>
import agent from '../utils/agent'

export default {
    data() {
        return {
            dialogVisible: '',
        }
    },
    methods: {

    },
    mounted() {
        let isMobile = agent()
        if (isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/lose.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/lose.scss')
        }
    }
}
</script>
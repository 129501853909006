<!-- 个人中心页面 -->

<template>
    <div class="personal">
        <div class="personal-content">
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>登录账号</span></div>
                <div class="personal-content-nickName-inputContet"><span>{{ dataForm.account }}</span></div>
            </div>
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>邮箱</span></div>
                <div class="personal-content-nickName-inputContet"><span>{{ dataForm.mailbox }}</span></div>
            </div>
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>订单查询密码</span></div>
                <div class="personal-content-nickName-inputContet">
                    {{ dataForm.searchPassword }}
                </div>
            </div>
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>会员级别</span></div>
                <div class="personal-content-nickName-inputContet"><span>{{ dataForm.level == 1 ? '普通会员' : '代理'
                        }}</span></div>
            </div>
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>余额</span></div>
                <div class="personal-content-nickName-inputContet"><span>￥ <span>{{ dataForm.balance }}</span></span>
                </div>
            </div>
            <div class="personal-content-nickName">
                <div class="personal-content-nickName-username"><span>重置密码</span></div>
                <div class="personal-content-nickName-inputContet">
                    <span style="font-weight: bolder;">******</span>
                    <span style="margin-left: 15px;cursor: pointer;color: #1890ff;font-size: 14px;"
                        @click="setPasswordButton" v-if="!setPassword"><i class="el-icon-info"></i> 编辑</span>
                </div>
            </div>
        </div>

        <div class="personal-mask" v-if="setPassword">
            <div class="personal-dialog">
                <div class="personal-dialog-header"><span>修改密码</span></div>
                <div class="personal-dialog-body">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="newPassword">
                            <el-input type="password" v-model="ruleForm.newPassword" autocomplete="off"
                                placeholder="请输入新密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item prop="reNewPassword">
                            <el-input type="password" v-model="ruleForm.reNewPassword" autocomplete="off"
                                placeholder="请再次输入新密码" show-password></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="personal-dialog-footer">
                    <div @click="submitForm">修改</div>
                    <div @click="setPasswordButton">取消</div>
                </div>
            </div>
        </div>
        <commonPopup ref="commonPopup" :tips="tips" :content="content" v-if="commonPopupVisable"></commonPopup>
        <delayedPopup :content="delayedPopupContent" :bgcolor="bgcolor" v-if="delayedPopupVisable"> </delayedPopup>
    </div>
</template>

<script>
import agent from '../utils/agent'
import commonPopup from '../components/common-popup.vue';
import delayedPopup from '../components/delayedPopup.vue'

export default {
    components: {
        commonPopup,
        delayedPopup
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.reNewPassword !== '') {
                    this.$refs.ruleForm.validateField('reNewPassword');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            tips: '',
            content: '',
            bgcolor: '',
            disabled: true,
            delayedPopupVisable: false,
            commonPopupVisable: false,
            delayedPopupContent: '',
            count: 0,
            dataForm: {},
            inputColor: 'red',
            setPassword: false,
            ruleForm: {
                id: '',
                newPassword: '',
                reNewPassword: ''
            },
            rules: {
                newPassword: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                reNewPassword: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        init() {
            this.dataForm = {}
            this.$http(`/sell/getMembers`, { method: 'GET', credentials: 'include' }).then((res) => {
                if (res.code == 0) {
                    this.dataForm = res.data
                } else {
                    this.$parent.loginout()
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        setPasswordButton() {
            this.setPassword = !this.setPassword
        },
        blur() {
            this.inputColor = 'red';
            this.count++
            if (this.count % 2 == 0) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.ruleForm.id = this.dataForm.id
                    this.$http.put(`/sell/resetPasswords`, this.ruleForm).then((res) => {
                        if (res.code == 0) {
                            this.setPassword = !this.setPassword
                            this.delayedPopupVisable = true
                            this.delayedPopupContent = '修改成功'
                            this.bgcolor = '#7fd852'
                            setTimeout(() => {
                                this.delayedPopupVisable = false
                                this.delayedPopupContent = ''
                                this.ruleForm = {}
                            }, 2000)
                        } else {
                            let isMobile = agent()
                            if (isMobile) {
                                this.commonPopupVisable = true
                                this.tips = '提示'
                                this.content = res.msg
                            } else {
                                this.$alert(res.msg, '提示', {
                                    dangerouslyUseHTMLString: true
                                });
                            }
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    return false;
                }
            });
        },
    },
    mounted() {
        this.dataForm = {}
        this.count = 0
        this.setPassword = false
        let isMobile = agent()
        if (isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/personal.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/personal.scss')
        }
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.personal {
    &-mask {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
}
</style>
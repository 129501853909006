import axios from "axios";
import store from '../store/index'

let instance = '';
instance = axios.create({
    baseURL: '/api',
    timeout: 18000
})

// 请求拦截器
instance.interceptors.request.use((config) => {
    if (config.url != '/sell/login') {
        axios.get(`/api/sell/getMembers`).then(({data: res}) => {
            if (res.code == 0) {
                store.commit('setSomeData', true)
            }
            if (res.code != 0) {
                store.commit('setSomeData', false)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    return config;
}, err => {
    return Promise.reject(err)
});


// 响应拦截器
instance.interceptors.response.use(res => {
    return res.data;
}, err => {
    return Promise.reject(err)
})

// 导出实例
export default instance
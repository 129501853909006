<!-- 支付页面 -->

<template>
    <div class="delayedPopup" :style="{ 'background-color': bgcolor, 'color': color }">
        <div class="delayedPopup-tips">{{ content }}</div>
    </div>
</template>

<script>
import agent from '../utils/agent'

export default {
    props: {
        content: {
            type: String,
            required: true,
            default: ''
        },
        bgcolor: {
            type: String,
            required: false,
            default: ''
        },
        color: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.delayedPopup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 45px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 45px;
    padding: 0 20px;
    z-index: 999999;
}
</style>
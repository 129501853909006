<!-- 支付页面 -->


<template>
    <div class="container">
        <div class="container-content">
            <div class="container-content-title">
                <h3 style="text-align: left;">订单已支付</h3>
            </div>
            <div class="container-content-title">
                <h4 style="text-align: left; color: #7b7b7b;"><span>订单{{ dataForm.orderNo }}已支付，您购买的订单如下</span></h4>
            </div>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="dataForm.cami" :disabled="true"
                :autosize="{ minRows: 4 }" style="margin-top: 15px;color: #000;">
            </el-input>
            <div slot="footer" class="footerButton">
                <el-button type="text" v-clipboard:copy="value" v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler">
                    一键复制
                </el-button>
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import agent from '../utils/agent'

export default {
    data() {
        return {
            dialogVisible: false,
            textarea: '123456',
            dataForm: {
                cami: ''
            },
            value: '123456789',
            query: {}
        }
    },
    methods: {
        init() {
            this.dialogVisible = true
            let orderNo = this.$route.query.orderNo
            this.query = this.$route.query.query
            this.$http.get(`/sell/getCami/${orderNo}`).then((res) => {
                if (res.code == 0) {
                    this.dataForm = res.data
                    this.value = this.dataForm.cami
                } else {
                    this.$message.error(res.msg)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        clipboardSuccessHandler(e) {
            this.$clipboard(this.value)
            let isMobile = agent()
            if (isMobile) {
                alert('复制成功')
            } else {
                this.$message.success("复制成功");
            }
        },
        clipboardErrorHandler({ value, event }) {
            console.log('error', value)
        },
        handleClose() {
            this.dialogVisible = false
            const userAgent = navigator.userAgent
            let isMobile = /Android/i.test(userAgent)
            if (isMobile) {
                this.$parent.back()
            } else {
                window.close()
            }
        },
    },
    mounted() {
        let isMobile = agent()
        if (isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/dialog.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/dialog.scss')
        }
        this.init()
    }
}
</script>

<style lang="scss" scoped>
:deep(.el-textarea.is-disabled .el-textarea__inner) {
    color: #000 !important;
    cursor: default;
    background-color: #fff;
}
</style>
<!-- 支付页面 -->


<template>
    <div class="container">
        <div class="container-content">
            <div class="container-content-title">
                <h3 style="text-align: left;">订单已支付</h3>
            </div>
            <div class="container-content-title">
                <h4 style="text-align: left; color: #7b7b7b;"><span>订单{{ dataForm.orderNo }}已支付，您购买的订单如下</span></h4>
            </div>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="dataForm.cami" :disabled="true"
                :autosize="{ minRows: 4 }" style="margin-top: 15px;color: #000;">
            </el-input>
            <div slot="footer" class="footerButton">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import agent from '../utils/agent'

export default {
    data() {
        return {
            dialogVisible: false,
            textarea: '123456',
            dataForm: {},
            query: {}
        }
    },
    methods: {
        init(orderNo) {
            this.dialogVisible = true
            this.$http.get(`/sell/getCami/${orderNo}`).then((res) => {
                if (res.code == 0) {
                    this.dataForm = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        handleClose() {
           this.dialogVisible = false
           this.$parent.close()
        },
    },
    mounted() {
        let isMobile = agent()
        if (isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/dialog.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/dialog.scss')
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.el-textarea.is-disabled .el-textarea__inner) {
    color: #000 !important;
    cursor: default;
    background-color: #fff;
}
</style>
<template>
    <div class="ManagementPlatform">
        <div class="ManagementPlatform-content" v-for="item, index in dataForm" :key="index">
            <div class="ManagementPlatform-content-title">{{ item.categoryName }}</div>
            <div class="ManagementPlatform-content-table">
                <el-table :data="item.goodsListVOList" style="font-size: 14px" v-if="isMobile == false"
                    :header-cell-style="{ 'background': '#f6f6f6', 'color': 'black', 'padding': '0', 'height': '45px', 'font-size': '15px', 'font-weight': isMobile ? 'normal' : 'bold' }"
                    :cell-style="{ 'color': 'black', 'font-weight': isMobile ? 'normal' : 'bold', 'padding': '0' }">
                    <el-table-column fixed prop="goodsName" label="商品名称">
                    </el-table-column>
                    <el-table-column prop="price" label="单价" width="90" align="center">
                    </el-table-column>
                    <el-table-column prop="stock" label="库存" width="80" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="70" align="center">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="deleteRow(scope.row.id)" type="text" size="small">
                                <span style="font-size: 24px;">购买</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div v-if="isMobile">
                    <div v-for="(item1, index1) in item.goodsListVOList" :key="index1" :style="{ 'border-bottom': item.goodsListVOList.length == index1 + 1 ? '1px solid #f2f2f2' : '' }"
                        style="padding: 8px;box-sizing: border-box; border-top: 1px solid #f2f2f2;">
                        <div class="ManagementPlatform-content-table-content">
                            {{ item1.goodsName }}
                        </div>
                        <div class="ManagementPlatform-content-table-information">
                            <div class="ManagementPlatform-content-table-information-top">
                                <div class="ManagementPlatform-content-table-information-top-num">库存 {{ item1.stock }}
                                </div>
                                <div class="ManagementPlatform-content-table-information-top-money">￥{{ item1.price }}
                                </div>
                            </div>

                            <div class="ManagementPlatform-content-table-information-buy"
                                @click="deleteRow(item1.id)">
                                {{ '购买' }}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <QueryRecordspopup ref="QueryRecordspopup"></QueryRecordspopup>
    </div>
</template>

<script>
import agent from '../utils/agent'
import QueryRecordspopup from '../views/QueryRecords/popUp';

export default {
    components: {
        QueryRecordspopup
    },
    data() {
        return {
            title: 'BM 商务管理平台',
            isMobile: false,
            dataForm: {},
            tableData: [
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },

                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
                {
                    date: '新创建BM---程序检测限额350美金--（能否接受像素随机）--未建立广告账户--适用于接受企业户以及个人户--促销价',
                    name: '￥3.00',
                    province: '341'
                },
            ]
        }
    },
    methods: {
        init() {
            this.$http.get(`/sell/goodsList`).then((res) => {
                if (res.code == 0) {
                    this.dataForm = res.data
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        deleteRow(id) {
            this.$refs.QueryRecordspopup.init(id)
        },
    },
    mounted() {
        this.isMobile = agent()
        if (this.isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/ManagementPlatform.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/ManagementPlatform.scss')
        }
        this.init()
    }
}
</script>
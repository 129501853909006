import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import lose from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/home/:id', name: 'home', component: HomeView },
  { path: '/404', name: 'lose', component: lose },
  { path: '/stop', name: 'stop', component: () => import('@/components/stop.vue') },
  { path: '/payment', name: 'payment', component: () => import('@/components/payment.vue') },
  { path: '/dialog', name: 'dialog', component: () => import('@/components/dialog.vue') },
  { path: '/dialogS', name: 'dialogS', component: () => import('@/components/dialogS.vue') },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

let isRedirecting = false;
router.beforeEach((to, from, next) => {
  const isWeChat = /MicroMessenger/.test(navigator.userAgent);
  console.log(isWeChat)
  if (isWeChat && to.path !== '/stop') { // 确保不是重定向到404页面  
    if (!isRedirecting) { // 检查是否已经在重定向过程中  
      isRedirecting = true; // 设置重定向标志为true  
      next('/stop'); // 重定向到404页面  
    } else {
      next(false); // 如果已经在重定向，则停止当前的导航  
    }
  } else {
    isRedirecting = false; // 重置重定向标志  
    if (to.path == '/payment' || to.path == '/dialog' || to.path == '/dialogS' || to.path == '/404' || to.path == '/stop' || (to.path.startsWith('/home/') && to.path.length > 6)) {
      next()
    } else {
      next('/404')
    }
  }
})

export default router

const agent = function(){
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // 使用正则表达式或其他逻辑来判断是桌面端还是移动端  
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    
    if (isMobile) {
       return true
    } else {
        return false
    }  
}

export default agent
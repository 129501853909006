<template>
  <div class="PurchaseGoods">
    <div class="PurchaseGoods-notice">
      <p class="PurchaseGoods-notice-tips">公告：</p>
      <div id="box-show" class="PurchaseGoods-notice-tip" style="position: relative;" v-loading="loading"
        v-html="dataForm.title"></div>
    </div>
  </div>
</template>

<script>
import agent from '../utils/agent'

export default {
  data() {
    return {
      loading: false,
      dataForm: {
        title: ''
      },
      obj: {},
    }
  },
  methods: {
    init() {
      this.loading = true
      this.$http.get(`/sell/generalNotice`).then((res) => {
        if (res.code == 0) {
          this.dataForm.title = res.data
          this.loading = false
        }
        let isMobile = agent()
        if (isMobile) {
          this.$nextTick(() => {
            this.checkImg()
          })
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    checkImg() {
      this.obj = document.getElementById('box-show').getElementsByTagName('img')
      if (this.obj) {
        for (let i = 0; i < this.obj.length; i++) {
          this.getImgInfo(this.obj[i].src, i)
        }
      }
    },
    getImgInfo(url, i) {
      let that = this;
      let image = new Image();
      let w, h;
      image.src = url;
      image.onload = function () {
        w = image.width
        h = image.height
        let windowWidth = document.documentElement.clientWidth
        if (windowWidth <= w) {
          that.obj[i].style.width = w / 1920 * windowWidth + 'px'
        }
        image.onload = null
      }
    },
  },
  mounted() {
    let isMobile = agent()
    if (isMobile) {
      console.log('移动端--------');
      import('../assets/CSS/mobile/PurchaseGoods.scss')
    } else {
      console.log('PC端--------');
      import('../assets/CSS/desktop/PurchaseGoods.scss')
    }
    this.init()

  }
}
</script>


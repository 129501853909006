<template>
    <div class="login" style="border-top: 1px solid #f2f2f2;">
        <div class="login-picture">
            <!-- <div class="login-picture-userLogin" v-if="isMobile">
                <div class="login-picture-userLogin-text1" v-show="!register && !setPassword">用户登录</div>
                <div class="login-picture-userLogin-text1" v-show="setPassword">修改密码</div>
                <div class="login-picture-userLogin-text2" v-show="!register && !setPassword">专业运维24小时处理、您的帐户安全将得到充分的保障。
                </div>
                <div class="login-picture-userLogin-text1" v-show="register && !setPassword">用户注册</div>
            </div> -->
        </div>
        <div class="login-show" :style="{ 'margin-top': !isMobile ? '20px' : '90px', background: '#fff' }">
            <div class="login-show-title" v-show="!register && !setPassword">用户登录</div>
            <div class="login-show-title" v-show="register">用户注册</div>
            <div class="login-show-title" v-show="setPassword">修改密码</div>
            <div class="login-show-content">
                <el-form :model="dataForm" :rules="rules" ref="dataForm" class="demo-dataForm">
                    <el-form-item prop="account" class="login-show-content-common">
                        <el-input v-model="dataForm.account" prefix-icon="el-icon-user"
                            placeholder="请输入登录账号"></el-input>
                    </el-form-item>
                    <el-form-item prop="mailbox" class="login-show-content-common" v-if="setPassword">
                        <el-input v-model="dataForm.mailbox" prefix-icon="el-icon-message"
                            placeholder="请输入邮箱账号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" class="login-show-content-common" v-if="!setPassword">
                        <el-input v-model="dataForm.password" prefix-icon="el-icon-unlock" show-password
                            placeholder="请输入登录密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword" class="login-show-content-common" v-if="setPassword">
                        <el-input v-model="dataForm.newPassword" prefix-icon="el-icon-unlock" show-password
                            placeholder="请输入登录密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="reNewPassword" class="login-show-content-common" v-if="setPassword">
                        <el-input v-model="dataForm.reNewPassword" prefix-icon="el-icon-unlock" show-password
                            placeholder="请再次输入登录密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="searchPassword" class="login-show-content-common" v-if="register">
                        <el-popover placement="top-end" width="200" trigger="hover">
                            <p style="color: #ff0000; font-weight: bold;"> <i class="el-icon-info"></i>
                                需牢记此处设置的查询密码。没有登陆账户时，点击【查询订单】必须输入此密码才可获取卡密。</p>
                            <el-input v-model="dataForm.searchPassword" prefix-icon="el-icon-unlock" slot="reference"
                                placeholder="请输入订单查询密码"></el-input>
                        </el-popover>
                    </el-form-item>
                    <el-form-item prop="mailbox" class="login-show-content-common" v-if="register">
                        <el-input v-model="dataForm.mailbox" prefix-icon="el-icon-message"
                            placeholder="请输入邮箱账号"></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha" v-if="!setPassword || setPassword">
                        <el-row :gutter="20">
                            <el-col :span="14">
                                <el-input v-model="dataForm.captcha" prefix-icon="el-icon-mobile-phone"
                                    placeholder="请输入验证码"></el-input>
                            </el-col>
                            <el-col :span="10" class="login-captcha"
                                style="height: 40px;display: flex;justify-content: center;align-items: center;">
                                <img :src="captchaPath" @click="getCaptcha()" style="width: 110px;">
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item class="login-show-content-forgotpassword" v-show="!register"
                        style="text-align: right;">
                        <span class="login-show-content-forgotpassword-text"
                            @click="setPassword = true, cleardataForm()" v-show="!setPassword">忘记密码</span>
                    </el-form-item>
                    <el-form-item class="login-show-content-forgotpassword" v-show="register || setPassword"
                        style="text-align: right;">
                        <span class="login-show-content-forgotpassword-text"
                            @click="register = false, setPassword = false, cleardataForm()">去登录</span>
                    </el-form-item>
                    <el-form-item style="margin: 0;">
                        <el-button type="primary" @click="submitForm(null)" style="width: 100%;"
                            v-show="!register && !setPassword">登录</el-button>
                        <el-button type="primary" @click="submitForm('register')" style="width: 100%;"
                            v-show="register && !setPassword">注册</el-button>
                        <el-button type="primary" @click="submitForm('setPassword')" style="width: 100%;"
                            v-show="setPassword">修改密码</el-button>
                    </el-form-item>
                    <el-form-item class="login-show-content-forgotpassword" style="text-align: right;">
                        <span class="login-show-content-forgotpassword-text" @click="register = true, cleardataForm()"
                            v-show="!setPassword && !register">立即注册</span>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <commonPopup :tips="tips" :content="content" v-if="commonPopupVisable"></commonPopup>
        <delayedPopup :content="delayedPopupContent" v-if="delayedPopupVisable"> </delayedPopup>
    </div>
</template>


<script>
import agent from '../utils/agent'
import commonPopup from '../components/common-popup.vue';
import delayedPopup from '../components/delayedPopup.vue'

export default {
    components: {
        commonPopup,
        delayedPopup
    },
    data() {
        const accountres = (rule, value, callback) => {
            if (value.length <= 8) {
                callback(new Error("登录名不少于8位"));
            } else {
                callback();
            }
        }
        const passwordres = (rule, value, callback) => {
            if (value.length <= 6) {
                callback(new Error("登录名不少于6位"));
            } else {
                callback();
            }
        }
        const isEmail = (rule, value, callback) => {
            if (!value) {
                callback();
            } else {
                const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
                const email = reg.test(value);
                if (!email) {
                    callback(new Error("邮箱格式不正确"));
                } else {
                    callback();
                }
            }
        }
        return {
            commonPopupVisable: false,
            delayedPopupVisable: false,
            tips: '',
            content: '',
            delayedPopupContent: '登陆成功',
            register: false,
            setPassword: false,
            dataForm: {
                account: '',
                password: '',
                newPassword: '',
                uuid: '',
                captcha: '',
                mailbox: '',
                searchPassword: '',
                reNewPassword: ''
            },
            isMobile: false,
            captchaPath: '',
            rules: {
                account: [
                    { required: true, message: '此项是必填项', trigger: 'blur' },
                    { validator: accountres, trigger: "blur" }
                ],
                password: [
                    { required: true, message: '此项是必填项', trigger: 'blur' },
                    { validator: passwordres, trigger: "blur" }
                ],
                captcha: [
                    { required: true, message: '此项是必填项', trigger: 'blur' }
                ],
                mailbox: [
                    { required: true, message: '此项是必填项', trigger: 'blur' },
                    { validator: isEmail, trigger: "blur" }
                ],
                searchPassword: [
                    { required: true, message: '此项是必填项', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '此项是必填项', trigger: 'blur' }
                ],
                reNewPassword: [
                    { required: true, message: '此项是必填项', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        // 获取验证码
        getCaptcha() {
            this.dataForm.uuid = this.getUUID()
            this.captchaPath = `/api/captcha?uuid=${this.dataForm.uuid}`
            this.$forceUpdate()
        },
        getUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                return (c === 'x' ? (Math.random() * 16 | 0) : (0x3 | 0x8)).toString(16)
            })
        },
        closeCommonPopup() {
            this.commonPopupVisable = false
        },
        submitForm(sign) {
            this.$refs.dataForm.validate((valid) => {
                if (!valid) {
                    return false
                }
                this.$http[sign == 'setPassword' ? 'put' : 'post'](sign == 'register' ? '/sell/register' : sign == 'setPassword' ? '/sell/forgetPassword' : '/sell/login', this.dataForm).then((res) => {
                    if (res.code !== 0) {
                        this.getCaptcha()
                        let isMobile = agent()
                        if (isMobile) {
                            this.commonPopupVisable = true
                            this.tips = '提示'
                            this.content = res.msg
                        } else {
                            this.$alert(res.msg, '提示', {
                                dangerouslyUseHTMLString: true
                            });
                        }
                        return fasle
                    }
                    let successMessgae = sign == 'register' ? '注册成功' : sign == 'setPassword' ? '修改成功' : '登陆成功'
                    if (successMessgae == '登陆成功') {
                        setTimeout(() => {
                            this.$parent.loginPersonal()
                            this.cleardataForm()
                        }, 500)
                    } else {
                        setTimeout(() => {
                            this.register = false
                            this.setPassword = false
                            this.cleardataForm()
                            this.getCaptcha()
                        }, 500)
                    }
                    this.delayedPopupVisable = true
                    this.delayedPopupContent = successMessgae
                    setTimeout(() => {
                        this.delayedPopupVisable = false
                        this.delayedPopupContent = ''
                    }, 2000)
                }).catch(() => { })
            })
        },
        cleardataForm() {
            if (this.$refs.dataForm != undefined) {
                this.$refs.dataForm.resetFields();
            }
        },
    },
    mounted() {
        this.isMobile = agent();
        if (this.isMobile) {
            console.log('移动端--------');
            import('../assets/CSS/mobile/login.scss')
        } else {
            console.log('PC端--------');
            import('../assets/CSS/desktop/login.scss')
        }
        this.getCaptcha()
        if (this.$refs.dataForm != undefined) {
            this.$refs.dataForm.resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
.login-picture {
    background: url('../assets/bg.png') no-repeat;
    background-size: cover;
}

// .activeColor{
//     color: red !important;
// }

.el-popover__content {
    color: red !important;
}
</style>